
export const SET_THEME = 'SET_THEME';
export const SET_LOADING = 'SET_LOADING';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const REGISTER_VISITOR = 'REGISTER_VISITOR';
export const REGISTER_USER = 'REGISTER_USER';
export const SAVE_REGISTER = 'SAVE_REGISTER'
export const RETURN_BEGIN = 'RETURN_BEGIN';

export const PASO = 'PASO'

export const DONATION = 'DONATION';
export const CARD = 'CARD'
export const PRICES = 'PRICES'
export const PRODUCTS = 'PRODUCTS'
export const CLEAR = 'CLEAR'

export const CHANGE_ROL = 'CHANGE_ROL'
export const PASO_D = 'PASO_D'

export  const  DONATIONS = 'DONATIONS'
export const CARDS = 'CARDS'

