import {SET_LOADING, SET_THEME} from '../../actions/types';

const dev = false;
const initialState = {
        bd: 'fundacion',
        loading: false,
        theme: 'light',
        url: dev ? 'http://localhost:3000' : 'https://educarparalavida.org',
        api: dev ? 'http://localhost:9080' : 'https://api.educarparalavida.org',
        app: 'fundacion',
        white: '#fff',
        gray0: '#f9fbfb',
        gray0b: '#f4f7f9',
        gray1: '#f1f1f1',
        gray2: '#e9f1f1',
        gray3: '#777',
        gray4: '#555',
        gray5: '#373737',
        gray7: '#1a1a1a',
        darkBlue: '#1d2b34',
        oceanBlue: '#0D2131',
        green: '#439393',
        lightgreen: '#2eba71',
        yellow: '#F1BC4D',
        red: '#DD3A4D',
        pink: '#e87b88',
        amber: '#7a6524',
        lightOceanBlue: '#CEDFF0',
        lighterOceanBlue: '#f0f4f7',
        darkgreen: '#95bcbc',
        blue: '#2E7AACFF',
        black:'#000000FF',
        role: 'Admin',
        regEmail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        regNum: /^[0-9\b]+$/,
        regDate: /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/,
        regCard: /^(?:(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11}))$/,
        regLetters: /^[A-Za-z\b\s]+$/,
        regName:/^([a-zA-Z]{2,}\s[a-zA-Z]+'?-?[a-zA-Z]{2,}\s?([a-zA-Z]+)?)/,
        idProductDonation: ''
    }
;

export default function RootReducer(state = initialState, action) {

    switch (action.type) {
        case SET_THEME:
            return {
                ...state,
                theme: action.payload,
                nombre: action.nombre
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        default:
            return {
                ...state,
                loading: false
            };
    }
}
