import {
    CARD, CLEAR,
    DONATION, PASO, PRICES, PRODUCTS
} from "../actions/types";

const initialState = {
    donationInfo: {
        type:"card"
    },
    card: {},
    paso: 1,
    prices : [],
    products: [],
    clear: false,
};

export default function DonationReducer(state = initialState, action){
    switch (action.type){
        case DONATION:
            return {
                ...state,
                donationInfo: action.payload
            }
        case CARD:
            return {
                ...state,
                card: action.payload
            }
        case PASO:
            return {
                ...state,
                paso: action.paso
            }
        case PRICES:
            return {
                ...state,
                prices: action.payload
            }
        case PRODUCTS:
            return {
                ...state,
                products: action.payload
            }
        case CLEAR:
            return {
                ...state,
                clear: !action.payload
            }
        default:
            return {
                ...state,
            }
    }

}