import {LOGIN, SET_LOADING} from "../../actions/types";

const initialState = {
    loading: false,
    user: sessionStorage.getItem('user'),
    currentRol: sessionStorage.getItem('currentRol')
};

export default function LoginReducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                user: action.payload,
                currentRol: action.currentRol,
                loading: false,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return {
                ...state,
                loading: false
            }

    }
}