import React, {useState} from 'react'

const MenuContext = React.createContext()

const {Provider} = MenuContext

function MenuContextProvider(props) {
    const [menuSelected, setMenuSelected] = useState('Cursos')
    const [subMenuSelected, setSubMenuSelected] = useState('Estadísticas')
    const [menuSelectedIndex, setMenuSelectedIndex] = useState(0)
    const [subMenuSelectedIndex, setSubMenuSelectedIndex] = useState(0)
    return <Provider value={{
        menuSelected,
        setMenuSelected,
        subMenuSelected,
        setSubMenuSelected,
        menuSelectedIndex,
        setMenuSelectedIndex,
        subMenuSelectedIndex,
        setSubMenuSelectedIndex
    }}>
        {props.children}
    </Provider>
}

export default MenuContext
export {MenuContextProvider, MenuContext}