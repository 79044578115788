import React, {Suspense} from 'react';
import {Route, Switch} from 'react-router-dom';
import App from './App';
import {Row} from "react-material-responsive-grid";
import {MenuContextProvider} from "./components/Globals/MenuContext";



const Landing = React.lazy(() => import('./components/Landing/Landing'));
const Login = React.lazy(() => import('./components/Login/Login'));
const Dashboard = React.lazy(() => import('./components/Dashboard/Dashboard'));
const Register = React.lazy(() => import('./components/Login/Register'));
const Blog = React.lazy(() => import('./components/Landing/Blog'));
const ForSchools = React.lazy(() => import('./components/Landing/ForSchools'));
const VolunteersPlans = React.lazy(() => import('./components/Landing/VolunteersPlans'));
const PROFON = React.lazy(() => import('./components/Landing/PROFONInfo'));
const PROFIN = React.lazy(() => import('./components/Landing/PROFINInfo'));
const PROFONRegister = React.lazy(() => import('./components/Landing/PROFONRegister'));
const PROFINRegister = React.lazy(() => import('./components/Landing/PROFINRegister'));

const Loading = () => {
    return (
        <Row className='uk-width-1-1'
             style={{
                 margin: 0,
                 padding: 0,
                 width: '100vw',
                 height: '100vh',
                 justifyContent: 'center',
                 alignItems: 'center',
                 backgroundColor: 'rgba(255,255,255,0.8)'
             }}>
            <img style={{width: '350px', objectFit: 'contain'}} src="./images/logoV1.png" alt=""/>
        </Row>)

}

const AppRoutes = () => (<Suspense fallback={<Loading/>}>
    <App>
        <Switch>
            <Route exact path='/' component={Landing}/>
            <Route exact path='/login' component={Login}/>
            <Route exact path='/register' component={Register}/>
            <Route exact path='/blog' component={Blog}/>
            <Route exact path='/forschools' component={ForSchools}/>
            <Route exact path='/VolunteersPlans' component={VolunteersPlans}/>
            <Route exact path='/profon' component={PROFON}/>
            <Route exact path='/profin' component={PROFIN}/>
            <Route exact path='/profonregister' component={PROFONRegister}/>
            <Route exact path='/profinregister' component={PROFINRegister}/>
            <MenuContextProvider>
                <Route exact path='/dashboard' component={Dashboard}/>
            </MenuContextProvider>
        </Switch>
    </App>
</Suspense>);

export default AppRoutes;
