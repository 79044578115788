import {
    REGISTER_USER,
    REGISTER_VISITOR, RETURN_BEGIN, SAVE_REGISTER
} from "../../actions/types";

const initialState = {
    loading : false,
    registered: false,
    visitor:{},

};

export default function RegisterReducer(state = initialState, action){
    switch (action.type){
        case REGISTER_VISITOR:
            return {
                ...state,
                registered: action.payload,
                loading: true
            };
        case REGISTER_USER:
            return {
                ...state,
                registered: action.payload,
                loading: true
            }
        case RETURN_BEGIN:
            state = initialState
            break;
        case SAVE_REGISTER:
            return {
                ...state,
                visitor: action.payload
            }
        default:
            return{
                ...state
            }

    }
}