import {CARDS, CHANGE_ROL, DONATIONS, PASO_D} from "../../actions/types";

const initialState = {
    user: sessionStorage.getItem('user'),
    currentRol: sessionStorage.getItem('currentRol'),
    paso: 1,
    donations: [],
    cards : [],
    requests: []
};

export default function DashboardReducer(state = initialState, action){
    switch (action.type){
        case CHANGE_ROL:
            return {
                ...state,
                currentRol: action.newRol,
            }
        case PASO_D:
            console.log("reducer", action)
            return {
                ...state,
                paso: action.payload
            }
        case DONATIONS:
            return {
                ...state,
                donations: action.payload
            }
        case CARDS:
            return {
                ...state,
                cards: action.payload
            }
        default:
            return {
                ...state
            }
    }

}
