import {
    LOGOUT
} from "../../actions/types";

const initialState = {
    loading: false,
    user: sessionStorage.getItem('user')
};

export default function LogoutReducer(state = initialState, action){
    switch (action.type){
        case LOGOUT:
            return {
                ...state,
                user: {},
            };
        default:
            return{
                ...state
            }

    }
}