import {combineReducers} from 'redux';
import RootReducer from './RootReducers/RootReducer';
import LoginReducer from "./LoginReducers/LoginReducer";
import LogoutReducer from "./LoginReducers/LogoutReducer";
import RegisterReducer from "./RegisterReducers/RegisterReducer";
import DonationReducer from "./DonationReducer";
import DashboardReducer from "./Dashboard/DashboardReducers";

export default combineReducers({
    root: RootReducer,
    login: LoginReducer,
    logout: LogoutReducer,
    register: RegisterReducer,
    donation: DonationReducer,
    dashboard: DashboardReducer,
})
