import React from 'react';
import './App.scss';

export default function App(props) {
    const {children} = props;
    return (
        <div className="App">
            {children}
        </div>
    );
}
